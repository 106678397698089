export default {
  async asyncData ({ store, route, /* error, */ redirect }) {
    const {path} = route;
    const content = await store.dispatch('getData', `${path}`)
      .catch(e => {
        if (e.status === 301) redirect(e.url);
        // eslint-disable-next-line no-console
        else console.error(e.status, e.message);
        // error(e);
      });
    return {content};
  },
  data () {
    return {
      content: {},
    };
  },
  head() {
    return this.content && this.content.extension 
      ? {
        title: this.content.extension.seo.title && this.content.extension.seo.title !== 'title'
          ? `${this.content.extension.seo.title} | Аэропорты Красноярья`
          : `Аэропорты Красноярья`,
        meta: [
          ...  this.content.extension.seo.description.length
            ? [{
              hid: 'description',
              name: 'description',
              content: this.content.extension.seo.description,
            }]
            : [],
          ...  this.content.extension.seo.keywords.length
            ? [{
              hid: 'keywords',
              name: 'keywords',
              content: this.content.extension.seo.keywords,
            }]
            : [],
        ],
      }
      : {};
  },
};