
import {rzSimpleTable, rzButton, rzIconFlightStart, rzIconFlightEnd, rzSelect, rzDateCalendarInput, rzPreloader, rzTabs} from 'razlet-ui';
import {mapGetters} from 'vuex';

export default {
  name: 'schedule',
  components: {
    rzSimpleTable,
    rzButton,
    rzIconFlightStart,
    rzIconFlightEnd,
    rzSelect,
    rzDateCalendarInput,
    rzPreloader,
    rzTabs,
  },
  props: {
    list: {
      type:Array,
      required:true,
    },
    loading: {
      type:Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableHead: [
        {name: 'Авиакомпания'},
        {name: 'Рейс'},
        {name: 'Направление'},
        {name: 'Дни вылета'},
        {name: 'Вылет'},
        {name: 'Прилет'},
        {name: 'Навигация'},
      ],
      tableHeadMobile: [
        {name: 'Данные рейса'},
        {name: 'Время'},
      ],
      activeTab: 'departure',
      tabs: [
        {
          value: 'departure',
          title: 'Вылет',
          icon: 'rz-icon-flight-start',
        },
        {
          value: 'arrival',
          title: 'Прилет',
          icon: 'rz-icon-flight-end',
        },
      ],
      date: {},
      airline: null,
      selectKey: 0,
      currentWeekday: null,
    };
  },
  computed: {
    ...mapGetters(['about']),
    city: function() {
      return this.about.title;
    },
    airlineOptions() {
      const companies = Array.from(new Set(this.list.map(item => item.companyName)));
      return companies.map(company => {
        return {
          label: company,
          value: company,
        };
      });
    },
    airlines() {
      if (!this.airline) return [];
      const keys = Object.keys(this.airline);
      return keys.filter(key => this.airline[key]);
    },
    filteredSchedule() {
      return this.list
        .filter(flight => {
          if (!this.airlines.length) return true;
          return this.airlines.includes(flight.companyName);
        })
        .filter(flight => {
          if (!this.isDateSelected) return true;
          const activeDays = flight.days
            .filter(day => day.active)
            .map(day => day.id);
          return activeDays.includes(this.currentWeekday);
        })
        .filter(flight => {
          if (!this.isDateSelected) return true;
          const filterDate = `${this.date.date}.${this.date.month.value}.${this.date.year}`;
          return flight.begin <= filterDate  && filterDate <= flight.end;
        });
    },
    isEmptyFilter() {
      return (!this.airline || (Object.values(this.airline).every(item => !item))) && !this.isDateSelected;
    },
    isDateSelected() {
      return Object.keys(this.date).length;
    },
  },
  watch: {
    date: {
      handler(value) {
        if (Object.keys(value).length) this.currentWeekday = this.getWeekday(value);
      },
      deep:true,
    },
  },
  methods: {
    getWeekday(date) {
      const value = new Date(`${date.month.value}/${date.date}/${date.year}`);
      return value.getDay();
    },
    resetFilter() {
      this.$set(this, 'airline', null);
      this.selectKey += 1;
      this.date = {};
    },
    switchTab(tab) {
      this.activeTab = tab;
      this.$emit('refresh', tab);
    },
  },
};
